/* Navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: transparent;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}

/* Scrolled navbar: blur effect */
.navbar.scrolled {
  background: rgba(43, 43, 43, 0.6); /* Semi-transparent background */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
}

/* Navbar Logo */
.navbar-logo img {
  height: 40px;
}

/* Hamburger Menu Styles */
.hamburger-menu {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  z-index: 1001; /* Ensure it stays above the dropdown */
  transition: transform 0.3s ease-in-out;
}

.hamburger-line {
  width: 25px;
  height: 3px;
  background-color: #4b3ae7;
  transition: all 0.3s ease-in-out;
}

.hamburger-menu.open .hamburger-line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger-menu.open .hamburger-line:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .hamburger-line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Dropdown menu styles */
.dropdown-menu {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(43, 43, 43, 0.9); /* Semi-transparent background */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  z-index: 999;
}

.dropdown-menu.open {
  top: 0;
  opacity: 1;
}

.dropdown-logo img {
  height: 150px;
  animation: fadeIn 0.5s ease-in-out;
}

.menu-items {
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-items li {
  margin: 30px 0;
}

.menu-items a {
  text-decoration: none;
  color: white;
  font-size: 22px;
  font-weight: 600;
  padding: 10px 20px;
  border: 2px solid #4b3ae7;
  border-radius: 25px;
  transition: all 0.3s ease;
}

.menu-items a:hover {
  color: #4b3ae7;
  background-color: white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
