/* General styles for the home component */
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden; /* Prevent overflow from animations */
}

/* Background fade slider container */
.image-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0; /* Start invisible */
  animation: fade 25s infinite; /* Fade animation across 5 images */
}

/* Individual image timings */
.slide:nth-child(1) {
  background-image: url("https://thesis.tjwit.com/images/determination.jpg");
  animation-delay: 0s;
}

.slide:nth-child(2) {
  background-image: url("https://thesis.tjwit.com/images/excitement.jpg");
  animation-delay: 5s;
}

.slide:nth-child(3) {
  background-image: url("https://thesis.tjwit.com/images/teamwork.jpg");
  animation-delay: 10s;
}

.slide:nth-child(4) {
  background-image: url("https://thesis.tjwit.com/images/victory-defeat.jpg");
  animation-delay: 15s;
}

.slide:nth-child(5) {
  background-image: url("https://thesis.tjwit.com/images/favorites.jpg");
  animation-delay: 20s;
}

/* Overlay for readability */
.overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

/* Home title styling */
.home-title {
  font-family: Shoal, serif;
  font-size: 10vh;
  color: white;
  z-index: 2;
  text-align: center;
}

/* Fade animation */
@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1; /* Fade in */
  }
  40% {
    opacity: 1; /* Stay visible */
  }
  50% {
    opacity: 0; /* Fade out */
  }
  100% {
    opacity: 0; /* Remain invisible until next cycle */
  }
}
