/* Background styling */
.client-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px; /* For smaller screens */
  background: linear-gradient(135deg, #2b2b2b, #303030);
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  box-sizing: border-box; /* Ensure padding doesn't affect centering */
}

/* Center the form and constrain its width */
.client-login form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

/* Headings and descriptions */
.client-login h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.client-login p {
  font-size: 1rem;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.5;
}

/* Input fields */
.login-input {
  border: none;
  border-radius: 8px;
  padding: 15px;
  width: 100%;
  margin-bottom: 15px;
  font-size: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Fix for consistent spacing */
}

.login-input:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

/* Login button */
.login-button {
  background-color: #4b3ae7;
  color: #fff;
  border: none;
  padding: 15px 30px;
  border-radius: 50px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 300px;
}

.login-button:hover {
  background-color: #3a2daf;
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(142, 94, 255, 0.3);
}

/* Error message */
.error {
  margin-top: 10px;
  color: #ff4c4c;
  font-weight: bold;
  text-shadow: none;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .client-login h1 {
    font-size: 2rem; /* Smaller heading size */
  }

  .client-login p {
    font-size: 0.9rem; /* Adjust paragraph size */
    margin-bottom: 20px;
  }

  .login-button {
    font-size: 1rem;
    padding: 12px 20px; /* Smaller button for mobile */
  }

  .login-input {
    padding: 12px; /* Adjust padding */
  }
}

@media (max-width: 480px) {
  .client-login h1 {
    font-size: 1.8rem; /* Further scale down for small screens */
  }

  .client-login p {
    font-size: 0.85rem;
    margin-bottom: 15px;
  }

  .login-button {
    font-size: 0.9rem;
    padding: 10px 15px;
  }

  .login-input {
    font-size: 0.9rem;
    padding: 10px;
  }
}
