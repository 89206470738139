.about {
  text-align: center;
  padding-top: calc(60px + 5vh); /* Prevents navbar overlap */
  background-color: #2b2b2b;
  min-height: 100vh;
}

/* Overall container styling */
.about-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  background-color: #121212;
  border-radius: 15px;
  overflow: hidden;
}

/* Hero Section */
.hero-section {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
  padding: 40px;
  background: linear-gradient(135deg, #1e1e1e, #2c2c2c);
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
}

.hero-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}

.about-heading {
  font-size: 3.5rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 15px;
  position: relative;
  text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.2);
}

.about-subheading {
  font-size: 1.3rem;
  color: #b3b3b3;
  position: relative;
}

/* CTA Button */
.cta-button {
  background: #4b3ae7;
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 20px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

.cta-button:hover {
  background: #ff652f;
}

.cta-button a {
  color: white;
  text-decoration: none;
}

/* Story Section */
.story-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.story-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background: linear-gradient(135deg, #1e1e1e, #2c2c2c);
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
  padding: 20px;
}

.story-text {
  flex: 1.2;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #e0e0e0;
  padding: 20px;
}

.story-image {
  flex: 1;
  text-align: center;
}

.story-image img {
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

/* Timeline Section */
.timeline-section {
  margin-top: 50px;
  padding: 30px;
  background: #121212;
  border-radius: 15px;
}

.timeline-event {
  background: linear-gradient(135deg, #1e1e1e, #2c2c2c);
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  margin: 20px auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
}

.timeline-event h3 {
  color: #ff4500;
}

.timeline-event p {
  color: #e0e0e0;
}

/* Vision Section */
.vision-section {
  text-align: center;
  background: linear-gradient(135deg, #1e1e1e, #2c2c2c);
  padding: 30px;
  border-radius: 15px;
  margin-top: 50px;
  font-size: 1.2rem;
  color: #e0e0e0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
}

.vision-section p {
  margin: 0;
}

.vision-image {
  flex: 1;
  text-align: center;
}

.vision-image img {
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

/* Responsive Design */
@media (max-width: 768px) {
  .story-content {
    flex-direction: column;
    text-align: center;
  }
}
