body {
  margin: 0;
  font-family: Arial, sans-serif;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Shoal"; /*Can be any text*/
  src: local("Shoal"), url("./fonts/Shoal-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Shoal-Bold";
  src: url("./fonts/Shoal-Bold.ttf") format("truetype");
}

body {
  font-family: "Shoal-Bold", Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  color: #333;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #121212;
  color: #333;
}
