/* Custom Scrollbar for Webkit-based browsers (Chrome, Edge, Safari) */

/* Scrollbar Track (Background) */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #2b2b2b; /* Background of the scrollbar track */
}

/* Scrollbar Handle (Thumb) */
::-webkit-scrollbar-thumb {
  background-color: #4b3ae7; /* Color of the scrollbar handle */
  border-radius: 10px; /* Rounded corners for a sleeker look */
}

/* Handle Hover Effect */
::-webkit-scrollbar-thumb:hover {
  background-color: #3a2cb6; /* Slightly darker blue when hovered */
}
