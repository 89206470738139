/* General Page Styling */
.portfolio-page {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #2b2b2b; /* Dark theme background */
  color: #ffffff;
  min-height: 100vh;
}

/* Title */
.portfolio-title {
  font-family: "Shoal", serif;
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

/* Description */
.portfolio-description {
  font-size: 1.2rem;
  color: #cccccc;
  margin-bottom: 2rem;
}

/* Support Button */
.support-button {
  background-color: #4b3ae7;
  color: #ffffff;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem; /* Space between text and heart */
}

.support-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(75, 58, 231, 0.5);
}

/* Bounce animation for the heart */
.bounce-heart {
  display: inline-block;
  animation: bounce 1.5s infinite ease-in-out;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

/* Grid Layout */
.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
}

/* Album Card */
.portfolio-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background-color: #393939;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 300px; /* Set a fixed height for all cards */
}

.portfolio-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(75, 58, 231, 0.5);
}

/* Album Image */
.portfolio-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 0.4s ease;
}

.portfolio-card:hover .portfolio-image {
  transform: scale(1.1);
}

/* Album Info with Semi-Transparent Background */
.album-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(43, 43, 43, 0.7); /* Semi-transparent dark */
  padding: 0.8rem 0;
  text-align: center;
}

.album-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff; /* Logo color */
  margin: 0;
}

/* Fade-in Animation */
.portfolio-card {
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  animation-delay: calc(0.2s * var(--animation-order));
}

.portfolio-card:nth-child(n) {
  --animation-order: calc(var(--animation-order, 0) + 1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
