/* Overall styling */
.client-page {
  padding: 50px 20px;
  text-align: center;
  background: #2b2b2b;
  min-height: 100vh;
}

.client-page h1 {
  font-size: 2.2rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.client-page p {
  font-size: 1rem;
  margin-bottom: 30px;
  color: #cecece;
  line-height: 1.5;
}

/* Albums container */
.albums-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

/* Individual album cards */
.album-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 250px;
  text-align: center;
  transition: all 0.3s ease;
  animation: fadeIn 0.5s ease-in;
}

.album-card:hover {
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Album image */
.album-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1px;
}

/* Album title */
.album-card h2 {
  font-size: 1.2rem;
  color: #4b3ae7;
  margin-bottom: 10px;
}

.album-card h2:hover {
  text-decoration: underline;
}

/* Link button */
.album-card button {
  display: inline-block;
  text-decoration: none;
  background-color: #4b3ae7;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.album-card a:hover {
  background-color: #3428a0;
}

/* Responsive styling */
@media (max-width: 1024px) {
  .album-card {
    width: 300px; /* Slightly larger cards for tablets */
  }
}

@media (max-width: 768px) {
  .albums-container {
    flex-direction: column;
    gap: 20px;
  }

  .album-card {
    width: 90%; /* Stretch to 90% width for smaller screens */
    margin: 0 auto;
  }

  .client-page h1 {
    font-size: 2rem; /* Adjust header size */
  }

  .client-page p {
    font-size: 0.9rem; /* Adjust paragraph font size */
  }
}

@media (max-width: 480px) {
  .album-card {
    width: 100%; /* Full width for extra-small screens */
  }

  .client-page h1 {
    font-size: 1.8rem; /* Scale header down further */
  }

  .client-page p {
    font-size: 0.85rem;
    margin-bottom: 20px;
  }

  .album-card a {
    font-size: 0.9rem; /* Smaller button text for mobile */
    padding: 8px 15px;
  }
}

/* Fade-in animation for albums */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
