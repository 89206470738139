.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2b2b2b;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  z-index: 1000;
}

.preloader.visible {
  opacity: 1;
  visibility: visible;
}

.preloader.hidden {
  opacity: 0;
  visibility: hidden;
}

.spinner {
  border: 6px solid #444;
  border-top: 6px solid #4b3ae7;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
