/* General Container */
.booking-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(to bottom right, #eef2f5, #dfe4ea);
  font-family: "Helvetica Neue", Arial, sans-serif;
}

/* Progress Bar */
.progress-bar {
  width: 80%;
  height: 8px;
  background: #e6e9f0;
  border-radius: 4px;
  margin-bottom: 30px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: #4b3ae7;
  transition: width 0.4s ease-in-out;
}

/* Question Card */
.question-card {
  width: 60%;
  max-width: 600px;
  background: #ffffff;
  padding: 25px 40px;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  text-align: center;
  position: relative;
  transform: translateX(0);
  opacity: 1;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.question {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
}

/* Input Fields */
input[type="text"],
input[type="email"],
input[type="time"],
input[type="date"] {
  width: 100%;
  padding: 12px 15px;
  margin-top: 15px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="time"]:focus,
input[type="date"]:focus {
  border-color: #4b3ae7;
  box-shadow: 0 4px 8px rgba(75, 58, 231, 0.2);
}

input[type="text"]::placeholder,
input[type="email"]::placeholder {
  color: #999999;
}

/* Choice Buttons */
.choice-options {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
}

.choice-button {
  background: #4b3ae7;
  color: #ffffff;
  border: none;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.choice-button:hover {
  background: #3a2ed9;
  transform: scale(1.05);
}

/* Navigation Buttons */
.navigation-buttons {
  margin-top: 25px;
  display: flex;
  gap: 15px;
}

.back-button,
.forward-button {
  background: #ffffff;
  color: #4b3ae7;
  border: 2px solid #4b3ae7;
  padding: 12px 30px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-button:hover,
.forward-button:hover {
  background: #4b3ae7;
  color: #ffffff;
}

.done-btn {
  background: #ffffff;
  color: #4b3ae7;
  border: 2px solid #4b3ae7;
  padding: 12px 30px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.done-btn:hover {
  background: #4b3ae7;
  color: #ffffff;
  transform: scale(1.5);
}

/* Animation Classes */
.slide-in-right {
  animation: slideInRight 0.4s forwards;
}

.slide-in-left {
  animation: slideInLeft 0.4s forwards;
}

.slide-out-left {
  animation: slideOutLeft 0.4s forwards;
}

.slide-out-right {
  animation: slideOutRight 0.4s forwards;
}

/* Success Modal */
.success-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.success-content {
  background: #ffffff;
  padding: 30px 40px;
  border-radius: 15px;
  text-align: center;
  animation: fadeIn 0.5s ease;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.success-content h2 {
  font-size: 24px;
  color: #4b3ae7;
  margin-bottom: 10px;
}

.success-content p {
  font-size: 16px;
  color: #333333;
}

/* Fade-In Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Keyframes */
@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .question-card {
    width: 90%;
    padding: 20px 30px;
  }

  .choice-options {
    flex-direction: column;
    gap: 10px;
  }

  .back-button,
  .forward-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

/* Information Section */
.info-section {
  background: #ffffff;
  padding: 40px 50px;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.5s ease;
  position: relative;
  max-width: 600px;
}

.info-section h1 {
  font-size: 28px;
  margin-bottom: 15px;
  color: #4b3ae7;
}

.info-section p {
  font-size: 18px;
  margin-bottom: 25px;
  color: #555555;
}

.continue-btn {
  background: #4b3ae7;
  color: #ffffff;
  border: none;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.continue-btn:hover {
  transform: scale(1.1);
  background: #3a2ed9;
}

/* Slide Animations */
@keyframes slideOutDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.slide-out-down {
  animation: slideOutDown 0.5s forwards;
}

.time-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
